import React from 'react';

import Teaser from '@common/teaser/teaser';
import Markup from '@common/markup/markup';
import FormButton from '@root/src/common/button/button';
import { useRouter } from 'next/router';

interface IndexTeaserInterface {
    image?: string;
    xlImage?: string;
    lgImage?: string;
    mobileImage?: string;
    tabletImage?: string;
    type?: string;
    buttonPosition?: string;
    video?: string;
    mobileVideo?: string;
    autoPlayVideo?: boolean;
    heading: string;
    description?: string;
    buttonLink: string;
    buttonText: string;
    absolute?: boolean;
    extraClass?: string;
}

const IndexTeaser: React.FC<IndexTeaserInterface> = props => {
    const router = useRouter();
    return (
        <Teaser
            wrapperPaddingClass="pt-32 lg:pt-36 xl:pt-40 xxl:pt-32"
            heightClass="h-full"
            image={props.image}
            xlImage={props.xlImage}
            lgImage={props.lgImage}
            mobileImage={props.mobileImage}
            tabletImage={props.tabletImage}
            video={props.video}
            mobileVideo={props.mobileVideo}
            autoPlayVideo={props.autoPlayVideo}
        >
            {props.heading && (
                <Markup
                    allowedTags={['img']}
                    allowedAttributes={['class']}
                    className="base-text-size-1 text-white leading-tighter text-shadow mb-8 lg:mb-8 xl:mb-6"
                    content={props.heading.replace(
                        '<realm>',
                        '<img class="mb-2 w-64 md:w-72 lg:w-80 xl:w-96 text m-auto md:ml-0 lg:ml-0 object-fill" src="/images/realm.png"/>'
                    )}
                />
            )}

            {props.description ? (
                <Markup
                    className={
                        'markdown-body text-white text-shadow-lg mb-10 px-4 md:px-0 text-center md:text-left text-xs md:text-base ' +
                        (props.type === 'half' ? 'lg:w-1/2 xl:w-1/4' : 'w-full')
                    }
                    content={props.description}
                />
            ) : (
                ''
            )}
            {props.buttonLink && (
                <div className={'text-center font-kg-medium ' + props.buttonPosition}>
                    <FormButton
                        label={props.buttonText}
                        onClick={e => {
                            e.preventDefault();
                            router.push(props.buttonLink);
                        }}
                    />
                </div>
            )}
        </Teaser>
    );
};

IndexTeaser.defaultProps = {
    autoPlayVideo: true,
    extraClass: '',
    absolute: false,
};

export default IndexTeaser;
