import React from 'react';

import Slider from '@common/slider/slider';

import IndexTeaser from '../teaser/teaser';
import { BannerOption } from '@config/banner';

interface BannerInterface {
    BannerData: BannerOption[];
}

const Banner = props => {
    return (
        <Slider
            wrapperClass="min-h-screen"
            slideWrapperClass="h-screen"
            pagerClass="text-2xl lg:text-3xl xl:text-4xl"
            pagerWrapperClass="mb-12 lg:mb-18"
            showTimers={false}
            slides={props.BannerData.map((value, index) => {
                return {
                    element: (
                        <IndexTeaser
                            key={index}
                            heading={value.heading}
                            image={value.image}
                            xlImage={value.xlImage}
                            lgImage={value.lgImage}
                            tabletImage={value.tabletImage}
                            mobileImage={value.mobileImage}
                            type={value.type}
                            buttonPosition={value.buttonPosition}
                            video={value.video}
                            mobileVideo={value.mobileVideo}
                            buttonLink={value.link}
                            buttonText={value.button}
                            autoPlayVideo={false}
                            extraClass={value.extraClass}
                            description={value.description}
                        />
                    ),
                    autoPlayDelay: value.speed,
                };
            })}
        />
    );
};

export default Banner;
