import { useBreakpoints, useWindowOrientation } from '@root/src/hooks/use-breakpoints';
import { useState } from 'react';

export const useBanner = () => {
    const { isMd, isLg, isXl } = useBreakpoints();
    const { isLandscape } = useWindowOrientation();
    const [bannerImage, setBannerImage] = useState('');
    const [bannerMode, setBannerMode] = useState('');

    const onChangeWidth = images => {
        setBannerImage(images.image);
        setBannerMode('desktopxxl');

        if (isLg && !isXl && images.xlImage) {
            setBannerImage(images.xlImage);
            setBannerMode('desktopxl');
        }

        if (isMd && !isLg && !isXl && images.lgImage) {
            setBannerImage(images.lgImage);
            setBannerMode('desktop');
        }

        if (!isLg && images.tabletImage) {
            if (!isLandscape) {
                setBannerImage(images.tabletImage);
            }
            setBannerMode('tablet');
        }

        if (!isMd && images.mobileImage) {
            if (!isLandscape) {
                setBannerImage(images.mobileImage);
            }
            setBannerMode('mobile');
        }
    };

    return { bannerImage, bannerMode, onChangeWidth };
};
