import React from 'react';
import Layout from '../common/layout';
import Banner from '@components/index/banner/banner';
import { BANNER_HOME } from '@config/banner';
import Head from 'next/head';

const IndexPage = () => (
    <React.Fragment>
        <Head>
            <title>Realm Workshop</title>
        </Head>
        <Banner BannerData={BANNER_HOME} />
    </React.Fragment>
);

export default Layout(IndexPage, { headerMode: 'fixed' });
