export interface BannerOption {
    heading: string;
    image?: string;
    xlImage?: string;
    lgImage?: string;
    curlyButtonAdditionalStyle?: string;
    type?: string;
    buttonPosition?: string;
    mobileImage?: string;
    tabletImage?: string;
    video?: string;
    mobileVideo?: string;
    link?: string;
    button?: string;
    speed?: number;
    extraClass?: string;
    absolute?: boolean;
    description?: string;
}

export const BANNER_HOME: BannerOption[] = [
    {
        heading: '<h2 class="pt-12"><realm></h2>',
        image: '/background.jpg',
        tabletImage: '/background.jpg',
        mobileImage: '/background-mobile.jpg',
        type: 'half',
        link: '/contact-us',
        button: 'CONNECT WITH US',
        buttonPosition: 'text-center md:text-left',
        extraClass: 'object-left-top',
        speed: 12,
        description: `<p class="mb-4">Realm is an architectural and interiors workshop led by select designers to create
        spaces that are compelling, with thoughtful consideration given to nature and surrounding circumstances.</p><p class="mb-4">Equal importance of concept and implementation is at the forefront of our practice. We thrive in continuous collaboration and are highly involved in every step of the project to ensure that ideas from paper are translated faithfully into each space.</p><p class="mb-4">At our core, we believe that design begins with simple yet meaningful intentions—be it for a hotel, business, or home.</p>`,
    },
];
