import React, { useEffect } from 'react';
import Image from 'next/image';

import { useBreakpoints } from '@hooks/use-breakpoints';
import { useBanner } from './useBanner';

interface TeaserInterface {
    image?: string;
    xlImage?: string;
    lgImage?: string;
    mobileImage?: string;
    tabletImage?: string;
    video?: string;
    mobileVideo?: string;
    autoPlayVideo?: boolean;
    wrapperClass?: string;
    innerWrapperClass?: string;
    contentWrapperClass?: string;
    heightClass?: string;
    wrapperPaddingClass?: string;
    baseContentClass?: string;
    baseHeightClass?: string;
}

const Teaser: React.FC<TeaserInterface> = props => {
    const { width } = useBreakpoints();
    const { bannerImage, bannerMode, onChangeWidth } = useBanner();

    useEffect(() => {
        onChangeWidth(props);
    }, [width]);

    return (
        <div
            className={
                'Teaser w-screen relative flex flex-col justify-start ' +
                ' ' +
                props.baseHeightClass +
                ' ' +
                props.wrapperPaddingClass +
                ' ' +
                props.heightClass +
                ' ' +
                props.wrapperClass
            }
            data-image-mode={bannerMode}
            data-banner-image={bannerImage}
        >
            <div className={'flex justify-center flex-col items-center ' + props.innerWrapperClass}>
                <div className="-z-10 h-full w-screen absolute top-0">
                    {bannerImage ? (
                        <React.Fragment>
                            <Image src={bannerImage} layout="fill" />
                            <div className="h-full w-screen bg-black"></div>
                        </React.Fragment>
                    ) : (
                        ''
                    )}
                </div>

                <div className={'Content z-50 ' + props.baseContentClass + ' ' + props.contentWrapperClass}>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

Teaser.defaultProps = {
    wrapperClass: '',
    innerWrapperClass: '',
    contentWrapperClass: '',
    heightClass: 'h-screen',
    wrapperPaddingClass: 'pt-32 lg:pt-48',
    baseContentClass: 'base-content-width xl:base-content-width-wide-1',
    baseHeightClass: 'base-content-height',
};

export default Teaser;
