import React from 'react';
interface ButtonProps {
    label: string;
    onClick: (event) => void;
    extraClassName?: string;
    baseButton?: boolean;
    disabled?: boolean;
}

const FormButton: React.FC<ButtonProps> = props => {
    return (
        <button
            onClick={props.onClick}
            disabled={props.disabled}
            type="button"
            className={`Button ${props.baseButton ? 'base-form-button' : ''} ` + props.extraClassName}
        >
            {props.label.toString()}
        </button>
    );
};

FormButton.defaultProps = {
    baseButton: true,
    disabled: false,
    extraClassName: 'text-white border-white',
};

export default FormButton;
